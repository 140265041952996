import React, { Component } from 'react';
import { TwitterHashtagButton } from 'react-twitter-embed';

class FooterSegment extends Component {

  render() {
    return (
        <div className="footer">
            <span className="bottomMessage">A cloud-connected kegerator brought to you by AWS Solutions Architects</span>
            <span className="twitter">
                <TwitterHashtagButton tag={'simplebeerservice'} />
            </span>
            <span className="location">Background Image<br /> <h3>AWS re:Invent, Las Vegas, NV USA</h3></span>
        </div>
    );
  }
}

export default FooterSegment;


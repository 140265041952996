import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import Dashboard from './pages/Dashboard';
import OurStory from './pages/OurStory';
import Unit from './pages/Unit';
import NotFound from './pages/NotFound';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

ReactDOM.render(
    <Router>
        <Switch>
          <Route exact path="/" component={Dashboard} />
          <Route exact path="/story" component={OurStory} />
          <Route exact path="/unit/:unitId" component={Unit} />
          <Route component={NotFound} />
        </Switch>
    </Router>,
    document.getElementById('root')
  )

/*
type Kegerator
  @model 
  @auth(rules: [
      {allow: groups, groups: ["Administrators"], mutations: [create, update, delete]}
    ])
{
  id: ID!
  kegerator: String 
  name: String
  lat: String
  long: String
  beerType: String
  beerLogo: String
}
*/

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();


export const getKegerator = `query GetKegerator($id: ID!) {
  getKegerator(id: $id) {
    id
    kegerator
    name
    calibration {
      cupSizeInML
      timeToFillInSeconds
      accelMinNotPouring
      accelMaxPouring
    }
    location {
      id
      name
      lat
      long
      kegerators {
        nextToken
      }
    }
    beer {
      items {
        id
        kegBeerLevelInPercent
      }
      nextToken
    }
  }
}
`;
export const listKegerators = `query ListKegerators(
  $filter: ModelKegeratorFilterInput
  $limit: Int
  $nextToken: String
) {
  listKegerators(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      kegerator
      name
      calibration {
        cupSizeInML
        timeToFillInSeconds
        accelMinNotPouring
        accelMaxPouring
      }
      location {
        id
        name
        lat
        long
      }
      beer {
        items {
            kegBeerLevelInPercent
            visitors
            beer {
                id
                name
                brewery
                logo
            }
        }
      }
    }
    nextToken
  }
}
`;
export const getLocation = `query GetLocation($id: ID!) {
  getLocation(id: $id) {
    id
    name
    lat
    long
    kegerators {
      items {
        id
        kegerator
        name
      }
      nextToken
    }
  }
}
`;
export const listLocations = `query ListLocations(
  $filter: ModelLocationFilterInput
  $limit: Int
  $nextToken: String
) {
  listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      lat
      long
      kegerators {
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getKegBeer = `query GetKegBeer($id: ID!) {
  getKegBeer(id: $id) {
    id
    kegerator {
      id
      name
      location {
        id
        name
        lat
        long
      }
    }
    beer {
      id
      name
      brewery
      logo
    }
    visitors
    calibration {
      minFlow
      maxFlow
      minSound
      maxSound
      emptyWeight
      fullWeight
    }
  }
}
`;
export const listKegBeers = `query ListKegBeers(
  $filter: ModelKegBeerFilterInput
  $limit: Int
  $nextToken: String
) {
  listKegBeers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      calibration {
        minFlow
        maxFlow
        minSound
        maxSound
        emptyWeight
        fullWeight
      }
      kegerator {
        id
        name
        location {
          id
          name
          lat
          long
        }
      }
      beer {
        id
        name
        brewery
        logo
      }
      visitors
    }
    nextToken
  }
}
`;
export const getBeer = `query GetBeer($id: ID!) {
  getBeer(id: $id) {
    id
    name
    brewery
    logo
    kegerators {
      items {
        id
        kegBeerLevelInPercent
      }
      nextToken
    }
  }
}
`;
export const listBeers = `query ListBeers(
  $filter: ModelBeerFilterInput
  $limit: Int
  $nextToken: String
) {
  listBeers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      brewery
      logo
      kegerators {
        nextToken
      }
    }
    nextToken
  }
}
`;

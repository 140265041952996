export const onUpdateKegerator = `subscription OnUpdateKegerator($owner: String!) {
  onUpdateKegerator(owner: $owner) {
    id
    name
    owner
    location {
      id
      name
      lat
      long
      kegerators {
        nextToken
      }
    }
    beer {
      items {
        id
      }
      nextToken
    }
  }
}
`;

export const onUpdateKegBeer = `subscription OnUpdateKegBeer {
  onUpdateKegBeer {
    id
    kegerator {
      id
      name
      location {
        id
        name
        lat
        long
      }
      beer {
        nextToken
      }
    }
    beer {
      id
      name
      brewery
      logo
      kegerators {
        nextToken
      }
    }
    visitors
  }
}
`;

export const onUpdateKegBeerByOwner = `subscription OnUpdateKegBeer($owner: String!) {
  onUpdateKegBeer(owner: $owner) {
    id
    kegerator {
      id
      name
      location {
        id
        name
        lat
        long
      }
      beer {
        nextToken
      }
    }
    beer {
      id
      name
      brewery
      logo
      kegerators {
        nextToken
      }
    }
    visitors
  }
}
`;

export const onUpdateKegBeerById = `subscription OnUpdateKegBeerById($id: ID!) {
  onUpdateKegBeerById(id: $id) {
    id
    kegerator {
      id
      name
      location {
        id
        name
        lat
        long
      }
      beer {
        nextToken
      }
    }
    beer {
      id
      name
      brewery
      logo
      kegerators {
        nextToken
      }
    }
    visitors
  }
}
`;
import React, { Component } from 'react'
import {
  Container,
  Icon,
  Menu,
  Responsive,
  Segment,
  Sidebar,
  Visibility,
} from 'semantic-ui-react'

const getWidth = () => {
  const isSSR = typeof window === 'undefined'

  return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth
}

class DesktopContainer extends Component {
  state = {}

  hideFixedMenu = () => this.setState({ fixed: false })
  showFixedMenu = () => this.setState({ fixed: true })

  render() {
    const { children } = this.props
    const { fixed } = this.state

    return (
      <Responsive getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth}>
        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}
        >
          <Segment
            inverted
            textAlign='center'
            style={{ padding: '1em 0em' }}
            vertical
          >
            <Menu
              fixed={fixed ? 'top' : null}
              inverted={!fixed}
              pointing={!fixed}
              secondary={!fixed}
              size='large'
            >
              <Container>
                <Menu.Item as='a' active>
                  Home
                </Menu.Item>
                <Menu.Item as='a'
                    href="https://medium.com/aws-activate-startup-blog/internet-of-beer-introducing-simple-beer-service-63c987037e9e"
                    target="_blank"
                    rel="noopener noreferrer"
                >Our Story</Menu.Item>
                <Menu.Item as='a'
                    href="http://github.com/awslabs/simplebeerservice"
                    target="_blank"
                    rel="noopener noreferrer"
                >Build Your Own</Menu.Item>
                <Menu.Item position='right'>
                    <div className="logo">
                        <img src="/sbslogo.png" className="App-logo" alt="logo" height="80" style={{float:"left"}}/> 
                        <h1 style={{ padding:0, margin:0}}>Simple Beer Service</h1>
                        <h4 style={{ padding:0, margin:0}}>Beer = Good, Streaming Beer = Better.</h4>
                    </div>
                </Menu.Item>
              </Container>
            </Menu>
          </Segment>
        </Visibility>

        {children}
      </Responsive>
    )
  }
}

class MobileContainer extends Component {
  state = {}

  handleSidebarHide = () => this.setState({ sidebarOpened: false })

  handleToggle = () => this.setState({ sidebarOpened: true })

  render() {
    const { children } = this.props
    const { sidebarOpened } = this.state

    return (
      <Responsive
        as={Sidebar.Pushable}
        getWidth={getWidth}
        maxWidth={Responsive.onlyMobile.maxWidth}
      >
        <Sidebar
          as={Menu}
          animation='push'
          inverted
          onHide={this.handleSidebarHide}
          vertical
          visible={sidebarOpened}
        >
          <Menu.Item as='a' active>
              Home
          </Menu.Item>
          <Menu.Item as='a'
                href="https://medium.com/aws-activate-startup-blog/internet-of-beer-introducing-simple-beer-service-63c987037e9e"
                target="_blank"
                rel="noopener noreferrer"
          >Our Story</Menu.Item>
          <Menu.Item as='a'
                href="http://github.com/awslabs/simplebeerservice"
                target="_blank"
                rel="noopener noreferrer"
          >Build Your Own</Menu.Item>
        </Sidebar>

        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Segment
            inverted
            textAlign='center'
            style={{ padding: '1em 0em' }}
            vertical
          >
            <Container>
              <Menu inverted pointing secondary size='large'>
                <Menu.Item onClick={this.handleToggle}>
                  <Icon name='sidebar' />
                </Menu.Item>
                <Menu.Item position='right'>
                <div className="logo">
                    <img src="/sbslogo.png" className="App-logo" alt="logo" height="80" style={{float:"left"}}/> 
                    <h1 style={{ padding:0, margin:0}}>Simple Beer Service</h1>
                    <h4 style={{ padding:0, margin:0}}>Beer = Good, Streaming Beer = Better.</h4>
                </div>
                </Menu.Item>
              </Menu>
            </Container>
          </Segment>

          {children}
        </Sidebar.Pusher>
      </Responsive>
    )
  }
}

const ResponsiveContainer = ({ children }) => (
  <div>
    <DesktopContainer className="desktop">{children}</DesktopContainer>
    <MobileContainer className="mobile">{children}</MobileContainer>
  </div>
)

export default ResponsiveContainer;
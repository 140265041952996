import React from 'react';
import BeerCard from '../components/BeerCard';
import Amplify, { graphqlOperation }  from 'aws-amplify';
import {getKegBeer} from '../graphql/customqueries';
import {onUpdateKegBeerById} from '../graphql/customsubscriptions';
import { Connect } from 'aws-amplify-react'; 
import FooterSegment from '../components/FooterSegment';
import aws_exports from '../aws-exports'; // specify the location of aws-exports.js file on your project

Amplify.configure({ ...aws_exports, "aws_appsync_authenticationType": "AWS_IAM"});

function Unit (props) {
  const unitId = props.match.params.unitId;
  console.log("Unit id", unitId);
 
  return (
    <div className="wrapper unit">
        <Connect query={graphqlOperation(getKegBeer, {id: unitId})}
          subscription={graphqlOperation(onUpdateKegBeerById, {"id": unitId})}
          onSubscriptionMsg={(prev, { onUpdateKegBeerById }) => {
              console.log ( "onUpdateKegBeer", onUpdateKegBeerById );
              console.log ( "prev", prev );
              prev.getKegBeer = {...prev.getKegBeer, ...onUpdateKegBeerById};
              return prev; 
          }}
          >
          {({ data: { getKegBeer }, loading, error }) => {
            if (error || !getKegBeer) return (<h3>Error</h3>);
            if (loading) return (<h3>Loading...</h3>);
            return (<BeerCard key={getKegBeer.id} id={getKegBeer.id} visitors={getKegBeer.visitors} beer={getKegBeer.beer} kegerator={getKegBeer.kegerator} calibration={getKegBeer.calibration}/>)
          }}
        </Connect>
      <FooterSegment />
    </div>
  );
}

export default Unit;
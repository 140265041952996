import React, { useState, useEffect } from 'react';
import SmoothieComponent, { TimeSeries } from 'react-smoothie';
import ReactMapboxGl from "react-mapbox-gl";
import Amplify, { Auth, PubSub } from 'aws-amplify';
import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';
import { mapBoxConfig, iotConfig } from '../config.js'

Amplify.addPluggable(new AWSIoTProvider({
    aws_pubsub_region: iotConfig.region,
    aws_pubsub_endpoint: iotConfig.endpoint,
}));

const Map = ReactMapboxGl({
    accessToken: mapBoxConfig.accessToken
});

const SMOOTHIE_OPTIONS = {
};


function BeerCard(props) {
    const [temperature, setTemperature] = useState(0);
    const [humidity, setHumidity] = useState(0);
    // TODO: figure out where we should set online, pouring, and hasVisitor. Should this come from the sensor topic?
    const [online, setOnline] = useState(true);
    const [isPouring, setPouring] = useState(false);
    const [hasVisitor, setHasVisitor] = useState(false);
    const [capacity, setCapacity] = useState(0);

    var liveData = {
        sound: new TimeSeries(SMOOTHIE_OPTIONS),
        flow: new TimeSeries(SMOOTHIE_OPTIONS)
    };

    console.log("props", props);

    useEffect(() => {
        // The following is used only to attach a policy to the cognito identity. 
        // TODO: replace this with a lambda function that executes on the creation of the user
        Auth.currentCredentials().then((info) => {
            if (info.data && info.data.IdentityId) {
                const cognitoIdentityId = info.data.IdentityId;
                console.log("Cognito Identity", cognitoIdentityId);
            } else {
                console.log("No Cognito Identity");
            }
        });

        const onUpdateSensors = (data) => {
            console.log("onUpdateSensors: data received", data);
            var time = new Date().getTime();

            var soundRange = 100;
            var flowRange = 100;
            var minSound = 0;
            var minFlow = 0;
            var emptyWeight = 0;
            var fullWeight = 100;

            if (props.calibration) {
                soundRange = props.calibration.maxSound - props.calibration.minSound;
                flowRange = props.calibration.maxFlow - props.calibration.minFlow;
                minSound = props.calibration.minSound;
                minFlow = props.calibration.minFlow;
                emptyWeight = props.calibration.emptyWeight;
                fullWeight = props.calibration.fullWeight;
            }
            if (data.value) {
                if (data.value.sound && data.value.sound.value && data.value.sound.value > 0) {
                    var adjustedSound = 100 * (parseFloat(data.value.sound.value) - minSound)/ soundRange; 
                    liveData.sound.append(time, adjustedSound);
                } else {
                    liveData.sound.append(time, 0.0);
                }

                if (data.value.flow && data.value.flow.value && data.value.flow.value > 0) {
                    var adjustedFlow = 100 * parseFloat((data.value.flow.value) - minFlow) / flowRange;
                    setPouring(adjustedFlow > 10);
                    liveData.flow.append(time, adjustedFlow);
                } else {
                    liveData.flow.append(time, 0.0);
                }

                if (data.value.temperature && data.value.temperature.value) {
                    var fahrenheit = data.value.temperature.value * 9/5 + 32;
                    setTemperature(fahrenheit);
                }

                if (data.value.humidity && data.value.humidity.value) {
                    setHumidity(data.value.humidity.value);
                }

                if (data.value.weight && data.value.weight.value) {
                    setCapacity(100 * (data.value.weight.value - emptyWeight) / (fullWeight - emptyWeight));
                }
            }
        };

        const sensorTopics = [
            'sbs/' + props.id + '/sensors',
        ];
        const sensorSub = PubSub.subscribe(sensorTopics).subscribe({
            next: onUpdateSensors,
            error: error => console.error(error),
            close: () => console.log('Done'),
        });

        return () => {
            sensorSub.unsubscribe();
        };
    }, [online, props.id]);

    function getPercentageBar() {
        let percentageLeft = capacity/100;
        let heightPx = percentageLeft * 205;
        return (
            <div className="percentageBar">
                <div className="percentageBarInside">
                    <span className="percentage" style={{ height: heightPx }}>{Math.round(percentageLeft * 100)}%</span>
                </div>
            </div>)
    }

    function getCurrentState() {
        if (!online) {
            return 'Offline'
        } else if (isPouring) {
            return 'Now Pouring!'
        } else if (hasVisitor) {
            return 'Visitor Detected!'
        } else {
            return 'Ready to Pour'
        }
    }

    return (
        <div className={online ? "beerCard card" : "beerCard card offline"} id={props.id}>
            {getPercentageBar()}
            <div className="kegerator">
                <div className="mug">
                    <div className={hasVisitor ? "empty" : "empty faded"}><img src="/sbs-empty.png" alt="empty" /></div>
                    <div className={isPouring ? "full show" : "full hidden"}><img src="/sbs-beer-filling.png" alt="full" /></div>
                </div>
                <div className="state">{getCurrentState()}</div>
                <div className="flowGraph">
                    <span className="chartTitle dataTitles">FLOW</span>
                    <SmoothieComponent
                        minValue={0}
                        maxValue={100}
                        millisPerPixel={50}
                        maxValueScale={3.0}
                        minValueScale={3.0}
                        millisPerLine={400}
                        responsive
                        height={80}
                        series={[
                            {
                                data: liveData.flow,
                                strokeStyle: { r: 255, g: 153, b: 0 },
                                fillStyle: { r: 255, g: 153, b: 0 },
                                lineWidth: 2
                            }
                        ]}
                    />
                </div>
            </div>
            <div className="beerData">
                <div className="title">
                    <a href={"/unit/" + props.id}><span className="titleText">{props.kegerator.name}</span></a>
                    <span className="subtitleText">{props.beer.name ? props.beer.name : "Root Beer"} from {props.beer.brewery ? props.beer.brewery : "Albita"}</span>
                </div>
                <div className="beerInfo">
                    <span className="beerLogo"><img src={props.beer.logo ? props.beer.logo : "https://abita.com/images/made/images/media_thumbs/Classic_Purple_Logo_JPEG_700_700.jpg"} alt="sbs" /></span>
                </div>
                <div className="dataDetails">
                    <div className="temperature dataBox"><span className="dataTitles">TEMP</span><span className="dataValues" style={{ color: '#4dff4d' }}>{temperature.toFixed(1)}°F</span></div>
                    <div className="humidity dataBox"><span className="dataTitles" >HUMIDITY</span><span className="dataValues" style={{ color: '#4dff4d' }}>{humidity.toFixed(1)}%</span></div>
                    <div className="visitors dataBox"><span className="dataTitles">VISITORS</span><span className="dataValues">{props.visitors}</span></div>
                </div>
                <div className="soundGraph">
                    <span className="chartTitle dataTitles">AMBIENT SOUND</span>
                    <SmoothieComponent responsive
                        height={80}
                        minValue={0}
                        maxValue={100}
                        millisPerPixel={50}
                        maxValueScale={3.0}
                        minValueScale={3.0}
                        millisPerLine={400}
                        series={[
                            {
                                data: liveData.sound,
                                strokeStyle: { r: 0, g: 255, b: 0 },
                                fillStyle: { r: 0, g: 255, b: 0 },
                                lineWidth: 2
                            }
                        ]}
                    />
                </div>
            </div>
            <div className="beerMap">
                <Map
                    center={[props.kegerator.location.long, props.kegerator.location.lat]}
                    zoom={[7]}
                    style={mapBoxConfig.style}
                    containerStyle={{
                        height: "100%",
                        width: "100%"
                    }}>
                </Map>
            </div>
        </div>
    );
}

export default BeerCard;
import React, { Component } from 'react';
import HeaderSegment from '../components/HeaderSegment';
import FooterSegment from '../components/FooterSegment';

class OurStory extends Component {

  render() {
    return (
      <div className="wrapper">
        <HeaderSegment />
        <div className="content">
            Our Story
        </div>
        <FooterSegment />
      </div>
    );
  }
}

export default OurStory;
import React, { Component } from 'react';

class NotFound extends Component {

  render() {
    return (
          <div className="NotFound">
            Doh! This page isn't here. 
          </div>
    );
  }
}

export default NotFound;
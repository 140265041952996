import React, { Component } from 'react';
import { Auth, Hub } from 'aws-amplify';
import {
  Container,
  Segment,
  Grid,
  Button,
  Icon,
} from 'semantic-ui-react';

class HeaderSegment extends Component {

  state = { user: null };

  componentDidMount() {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          this.setState({ user: data });
          break;
        case "signOut":
          this.setState({ user: null });
          break;
        default: 
          console.log("default");
          break;
      }
    });
  }
  render() {
    return (
      <Container style={{width:"100%"}}>
        <Segment vertical style={{ padding: '2em 0em', width: '100%' }}>
          <Container>
            <Grid divided inverted stackable>
              <Grid.Row className="header">
                <Grid.Column width={12}>
                <div className="logo">
                  <img src="/sbslogo.png" className="App-logo" alt="logo" height="80" style={{float:"left"}}/> 
                  <h1 style={{ padding:0, margin:0}}>Simple Beer Service</h1>
                  <h4 style={{ padding:0, margin:0}}>Beer = Good, Streaming Beer = Better.</h4>
                </div>
                </Grid.Column>
                <Grid.Column width={12}>
                  <div className="nav">
                    <ul className="navbar">
                      <li className="divider">
                        <a
                          className="button"
                          href="https://medium.com/aws-activate-startup-blog/internet-of-beer-introducing-simple-beer-service-63c987037e9e"
                          target="_blank"
                          rel="noopener noreferrer"
                        >Our Story</a>
                      </li>
                      <li className="divider">
                        <a
                          className="button button-primary"
                          href="http://github.com/awslabs/simplebeerservice"
                          target="_blank"
                          rel="noopener noreferrer"
                        >Build Your Own</a>
                      </li>
                      <li>
                        <Button size='small' onClick={() => Auth.signOut()}>
                          <Icon name='sign out' /> Sign Out
                        </Button>
                      </li>
                    </ul>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </Segment>
      </Container>
    );
  }
}

export default HeaderSegment;

import React, { Component } from 'react';
import '../styles/App.css';
import BeerCard from '../components/BeerCard';
import ResponsiveHeader from '../components/ResponsiveHeader';
import FooterSegment from '../components/FooterSegment';
import Amplify, { graphqlOperation }  from 'aws-amplify';
import {listKegBeers} from '../graphql/customqueries';
import {onUpdateKegBeer} from '../graphql/customsubscriptions';
import { Connect } from 'aws-amplify-react'; 
//import { Auth } from 'aws-amplify';
import aws_exports from '../aws-exports'; // specify the location of aws-exports.js file on your project

Amplify.configure({ ...aws_exports, "aws_appsync_authenticationType": "AWS_IAM"});

const ContentContainer = (props) => (<div className="content">{props.children}</div>);

const TapListView = ({ taps }) => {
  const renderedBeerCards = taps.map(tap => 
    <BeerCard key={tap.id} id={tap.id} visitors={tap.visitors} beer={tap.beer} kegerator={tap.kegerator} calibration={tap.calibration}/>
  );

  if (renderedBeerCards.length) {
    return (<ContentContainer>{renderedBeerCards}</ContentContainer>);
  }

  return (
    <ContentContainer>
      <h3>Uh oh!</h3>
      <p>There are no beer cards to render. Please check your Cognito Identity and configuration to IoT.</p>
    </ContentContainer>
  );
};

const renderConnectedDashboard = (user) => {
  var queryParams = {};
  const subscriptionParams = { };
  const query = graphqlOperation(listKegBeers, queryParams);
  const subscription = graphqlOperation(onUpdateKegBeer, subscriptionParams);
  const onSubscriptionMsg = (prev, { onUpdateKegBeer }) => {
    console.log ( "onUpdateKegBeer", onUpdateKegBeer );
    console.log ( "prev", prev );
    var items = prev.listKegBeers.items.map(tap => {
      if (tap.id && onUpdateKegBeer.id && tap.id === onUpdateKegBeer.id) {
        return { ...tap, ...onUpdateKegBeer };
      }
      return tap;
    });
    prev.listKegBeers.items = items;
    return prev; 
  };

  return (
    <Connect
      query={query}
      subscription={subscription}
      onSubscriptionMsg={onSubscriptionMsg}
      >
        {({ data: {listKegBeers}, loading, error }) => {
          if (error) return (<ContentContainer><h3>Error</h3></ContentContainer>);
          if (loading || !listKegBeers) return (<ContentContainer><h3>Loading...</h3></ContentContainer>);
          return (<TapListView taps={listKegBeers.items} />)
        }}
    </Connect>
  );

}

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = { user: null };
  }

  /*
  componentDidMount() {
    Auth.currentAuthenticatedUser().then(user => {
        console.log(user);
        this.setState({ user: user.username });
    });
  }
  */

  render() {
    const { user } = this.state;
    return (
      <div className="wrapper">
        <ResponsiveHeader>
          { renderConnectedDashboard(user) }
          <FooterSegment />
        </ResponsiveHeader>
      </div>
    );
  }
}

export default Dashboard;

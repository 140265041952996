// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:e946b774-116d-4348-8611-18054af7ab8a",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_2XQ3ieI1G",
    "aws_user_pools_web_client_id": "494bvvgj0g68ot1j168m67nqi3",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://r3x2rpvpkjfq5fwuluyypefbxe.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_content_delivery_bucket": "sbs-20190624210335-hostingbucket-ri",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d36rrcz4ojgedp.cloudfront.net"
};


export default awsmobile;
